section.featured-news {
  position: relative;
  padding: 110px 20px 160px;
  background-color: #042956;
  background-image: url('/themes/athena/assets/dist/images/troopers-news-bkgd.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
  @include medium-up {
    padding: 125px 20px 200px;
  }

  // &:before {
  //   content: '';
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   top: 0;
  //   left: 0;
  //   z-index: -1;
  //   background: rgba($primary-color, 0.3);
  // }

  h2 {
    margin: 0 0 20px;
    color: $featured-news-heading-color;
    font-weight: $regular;
    font-style: normal;
    font-size: 36px;
    @include medium-up {
      max-width: 1600px;
      margin: 0 auto 52px;
    }
  }

  .news-items {
    width: 100%;
    display: block;
    @include medium-up {
      display: flex;
      max-width: 1600px;
      margin: 0 auto;
    }

    .owl-stage {
      display: flex;
    }

    .news-item {
      position: relative;
      display: inline-block;
      overflow: hidden;
      width: 100%;
      vertical-align: top;
      background: white;
      margin: 15px 0;
      text-align: center;
      box-shadow: 0 0 10px rgba(0, 0, 0, .15);
      height: 100%;

      // @include medium-up {
      //   width: 33%;
      //   margin: 0 15px;
      // }

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      .news-item-image {
        width: 100%;
        position: relative;
      }

      .news-item-info {
        padding: 30px;

        h3 {
          margin: 0 0 10px;
          font-size: 28px;
          font-weight: $bold;
          font-family: $primary-font;

          a {
            color: $primary-color;
            text-decoration: none;
            line-height: 24px;
            border: none;

            &:hover,
            &:focus {
              color: $tertiary-color;
            }
          }
        }

        .news-item-date {
          font-size: 14px;
          font-weight: $bold;
          color: $news-date-color;
          letter-spacing: 1px;
        }

        .news-item-blurb {
          font-size: 18px;
          line-height: 24px;
          font-weight: $regular;
          color: $news-blurb-color;
          padding-top: 20px;
        }

        .news-item-read-more {
          a {
            color: $primary-color;
            text-decoration: none;
            font-weight: 700;
            font-size: 14px;
            text-transform: uppercase;
            letter-spacing: 1px;

            &:hover,
            &:focus {
              color: $tertiary-color;
            }
          }
        }
      }
    }
  }

  .view-all-news {
    max-width: 1600px;
    margin: auto;
    margin-top: 44px;

    a {
      color: $news-view-all-color;
      text-decoration: none;
      font-weight: $regular;
      font-size: 24px;
      border: none;

      &:hover,
      &:focus {
        color: white;
      }
    }
  }
}