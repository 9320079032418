section.featured-events {
  padding: 110px 20px 160px;
  background-image: url('/themes/athena/assets/dist/images/featured-events.png');
  background-size: cover;
  position: relative;
  z-index: 0;


  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: rgba(255, 255, 255, 0.7);
  }

  h2 {
    margin: 0 0 20px;
    color: $events-featured-heading;
    font-weight: $regular;
    font-style: normal;
    font-size: 34px;

    @include medium-up {
      max-width: 1600px;
      margin: 0 auto 30px;
    }
  }

  .events {
    width: 100%;
    display: block;

    @include medium-up {
      display: flex;
      max-width: 1600px;
      margin: 0 auto;
    }

    .event {
      position: relative;
      display: inline-block;
      overflow: hidden;
      width: 100%;
      vertical-align: top;
      background: white;
      margin: 15px 0;
      text-align: center;
      box-shadow: 0 0 10px rgba(0, 0, 0, .15);

      // @include medium-up {
      //   width: 33%;
      //   margin: 0 15px;
      // }

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      .event-image {
        width: 100%;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 0;
          background: rgba(0, 0, 0, 0.7);
        }
      }

      .event-info {
        padding: 30px;

        .event-wrap {
          position: absolute;
          top: 30%;
          text-align: center;
          width: 100%;
          left: 0;
          padding: 0 20px;

          h3 {
            margin: 0 0 10px;
            font-size: 28px;
            font-weight: $bold;

            a {
              color: $events-title-color;
              text-decoration: none;
              line-height: 24px;
              border: none;

              &:hover,
              &:focus {
                color: $tertiary-color;
              }
            }
          }

          .event-date {
            font-size: 14px;
            font-weight: $bold;
            color: $events-date-color;
            letter-spacing: 1px;
          }
        }



        .event-blurb {
          font-size: 16px;
          line-height: 24px;
          font-weight: $regular;
          color: $events-blurb-color;
          margin-top: 0;
        }

        .event-read-more {

          a {
            color: $primary-color;
            text-decoration: none;
            font-weight: 700;
            font-size: 14px;
            text-transform: uppercase;
            letter-spacing: 1px;

            &:hover,
            &:focus {
              color: $tertiary-color;
            }
          }
        }
      }
    }
  }

  .view-all-events {
    padding: 10px 0 0;
    max-width: 1600px;
    margin: auto;

    a {
      color: $events-view-all-color;
      text-decoration: none;
      font-weight: $regular;
      font-size: 22px;
      border: none;

      &:hover,
      &:focus {
        color: white;
      }
    }
  }

  .owl-nav {

    .owl-prev,
    .owl-next {

      &:before {
        color: $events-slider-buttons;
      }
    }
  }

  .owl-dots {

    .owl-dot {
      background: $events-slider-pagers;
      color: $events-slider-pagers;

      &.active {
        background: $events-slider-pagers;
        color: $events-slider-pagers;
      }
    }
  }
}