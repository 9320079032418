/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/

// Presets & Variables
@import 'presets/variables';
@import 'presets/mixins';

// Additional Presets & Variables
@import 'presets/grid-settings';
@import 'presets/helper-classes';

// Vendor
@import 'vendor/owl.carousel';

// Layout

// Components
@import 'components/homepage-callouts';
@import 'components/featured-news';
@import 'components/featured-events';
@import 'components/owl-slider';

// Modules